import React, { Component, Fragment } from 'react';
import Cookies from 'js-cookie';
import { Redirect, Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import {Helmet} from "react-helmet";
import logo1 from './logo-mxt.png';
//import logo2 from './logo-cmtk.png';
import logo2 from './logo-associazione-tokalon.png';
import logo3 from './logo-ixt.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Modal from '@mui/material/Modal';
import Subheader from './Subheader';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import HomeButton from './HomeButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Logo from './Logo';
import TokalonButton from './TokalonButton';

class Home extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            authorization: Cookies.get('authorization') || '',
            user_email: Cookies.get('user_email') || '',
            nome: Cookies.get('nome') || '',
            cognome: Cookies.get('cognome') || '',
            newuser: Cookies.get('newuser') || "false",
            anchorElUserMenu: null,
            totale_ordini: 0,
            totale_ordini_da_colleghi: 0,
            totale_ordini_formazione: 0,
            in_corso_supporter: [],
            in_corso_math: [],
            in_corso_italian: [],
            attivi: [],
            elenco_progetti_attivi: [],
            elenco_docenti_referenti_progetto: [],
            elenco_categorie_classe: [],
            docente_primo_ordine_math: false,
            docente_primo_ordine_italian: false,
            modifyaddressenabled: false,
            confirmaddressenabled: false,
            projectaddress: "",

            error: false,
            errorcode: '',
            errormessage: '',
            isloading: false,

            sped_nome: "",
            sped_cognome: "",
            sped_nome_scuola: "",
            sped_indirizzo: "",
            sped_citta: "",
            sped_provincia: "XX",
            sped_cap: "",
            sped_cellulare: "",
            note_consegna: "",

            sped_nome_error: false,
            sped_cognome_error: false,
            sped_nome_scuola_error: false,
            sped_indirizzo_error: false,
            sped_citta_error: false,
            sped_provincia_error: false,
            sped_cap_error: false,
            sped_cellulare_error: false,
            note_consegna_error: false,

            is_supporter: false,

            opendetailmodal: false,
            openmathdetailmodal: false,
            openitaliandetailmodal: false,
            class_to_show: 0,
            order_to_show: 0,

            trainingclicked: false,
            registerteamclicked: false,
            manageteamclicked: false,

            pulsante_iscrivi_squadre: false,

            avviso_attivo: false,
            testo_avviso: "",
            iscrizioni_supporter_are_active: false,
            iscrizioni_mxt_are_active: false,
            iscrizioni_ixt_are_active: false,
            iscrizioni_squadre_mxt_are_active: false,
            iscrizioni_squadre_ixt_are_active: false,
            id_progetto_sup: null,
            id_progetto_mxt: null,
            id_progetto_ixt: null,
            edizione_attiva: null,
            spedizione_attiva: false,
            n_ordini_edizioni_precedenti: 0,

            filtro_progetto: "allprj",
            filtro_categoria: "allcat"
        };
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
        this.handleModifyAddress = this.handleModifyAddress.bind(this);
        this.handleChangeSpedNome = this.handleChangeSpedNome.bind(this);
        this.handleChangeSpedCognome = this.handleChangeSpedCognome.bind(this);
        this.handleChangeSpedNomeScuola = this.handleChangeSpedNomeScuola.bind(this);
        this.handleChangeSpedIndirizzo = this.handleChangeSpedIndirizzo.bind(this);
        this.handleChangeSpedCitta = this.handleChangeSpedCitta.bind(this);
        this.handleChangeSpedProvincia = this.handleChangeSpedProvincia.bind(this);
        this.handleChangeSpedCap = this.handleChangeSpedCap.bind(this);
        this.handleChangeSpedCellulare = this.handleChangeSpedCellulare.bind(this);
        this.handleChangeNoteConsegna = this.handleChangeNoteConsegna.bind(this);
        this.handleConfirmAddress = this.handleConfirmAddress.bind(this);

        this.handleOpenDetailModal = this.handleOpenDetailModal.bind(this);
        this.handleOpenMathDetailModal = this.handleOpenMathDetailModal.bind(this);
        this.handleOpenItalianDetailModal = this.handleOpenItalianDetailModal.bind(this);
        this.handleCloseDetailModal = this.handleCloseDetailModal.bind(this);
        this.handleCloseItalianDetailModal = this.handleCloseItalianDetailModal.bind(this);
        this.handleCloseMathDetailModal = this.handleCloseMathDetailModal.bind(this);

        this.handleTraining = this.handleTraining.bind(this);
        this.handleRegisterTeam = this.handleRegisterTeam.bind(this);
        this.handleManageTeam = this.handleManageTeam.bind(this);
        this.handleChangeFilterProject = this.handleChangeFilterProject.bind(this);
        this.handleChangeFilterCategory = this.handleChangeFilterCategory.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.setState({ isloading: true });
        }        
        var config = require('./config.json');

        var pulsante_iscrivi_squadre = config.pulsante_iscrivi_squadre;
        
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'users/getorders';
        var authorization = config.api_auth_backend[env_backend];
        var email = this.state.user_email;

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        axios.post(apiUrl, { 
            "email" : email
        }, settings).then(res => {
            //console.log(res);
            
            if (this._isMounted) {
                this.setState({ pulsante_iscrivi_squadre: pulsante_iscrivi_squadre});
                this.setState({ codice_meccanografico_scuola: res.data.data.codice_meccanografico_scuola || ""}); 
                this.setState({ nome_scuola: res.data.data.nome_scuola_utente || ""}); 
                this.setState({ citta_scuola: res.data.data.citta_scuola_utente || ""}); 
                this.setState({ is_supporter: res.data.data.is_supporter || false}); 
                this.setState({ totale_ordini: res.data.data.totale_ordini || 0}); 
                this.setState({ totale_ordini_formazione: res.data.data.totale_ordini_formazione || 0}); 
                this.setState({ totale_ordini_da_colleghi: res.data.data.ordini_da_colleghi || 0}); 
                this.setState({ in_corso_supporter: res.data.data.in_corso_supporter || []}); 
                this.setState({ in_corso_math: res.data.data.in_corso_math || []}); 
                this.setState({ in_corso_italian: res.data.data.in_corso_italian || []}); 
                this.setState({ docente_primo_ordine_math: res.data.data.docente_primo_ordine_math || false}); 
                this.setState({ docente_primo_ordine_italian: res.data.data.docente_primo_ordine_italian || false}); 
                this.setState({ attivi: res.data.data.attivi || []}); 
                this.setState({ elenco_progetti_attivi: res.data.data.elenco_progetti_attivi || []}); 
                this.setState({ elenco_docenti_referenti_progetto: res.data.data.elenco_docenti_referenti_progetto || []}); 
                this.setState({ elenco_categorie_classe: res.data.data.elenco_categorie_classe || []}); 
                this.setState({ avviso_attivo: res.data.data.avviso_attivo || 0})
                this.setState({ testo_avviso: res.data.data.testo_avviso || ""})
                this.setState({ iscrizioni_supporter_are_active: res.data.data.iscrizioni_supporter_are_active || false });
                this.setState({ iscrizioni_mxt_are_active: res.data.data.iscrizioni_mxt_are_active || false });
                this.setState({ iscrizioni_ixt_are_active: res.data.data.iscrizioni_ixt_are_active || false });
                this.setState({ iscrizioni_squadre_mxt_are_active: res.data.data.iscrizioni_squadre_mxt_are_active || false });
                this.setState({ iscrizioni_squadre_ixt_are_active: res.data.data.iscrizioni_squadre_ixt_are_active || false });
                this.setState({ id_progetto_sup: res.data.data.id_progetto_sup || null });
                this.setState({ id_progetto_mxt: res.data.data.id_progetto_mxt || null });
                this.setState({ id_progetto_ixt: res.data.data.id_progetto_ixt || null });
                this.setState({ edizione_attiva: res.data.data.edizione_attiva || null });
                this.setState({ spedizione_attiva: Boolean(res.data.data.spedizione_attiva) || false });
                this.setState({ n_ordini_edizioni_precedenti: res.data.data.n_ordini_edizioni_precedenti});
                this.setState({ isloading: false });
            }

        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        });    
    }

    reloadOrders(){
        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'users/getorders';
        var authorization = config.api_auth_backend[env_backend];
        var email = this.state.user_email;

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        axios.post(apiUrl, { 
            "email" : email
        }, settings).then(res => {
            //console.log(res);
            
            if (this._isMounted) {
                this.setState({ nome_scuola: res.data.data.nome_scuola || ""}); 
                this.setState({ is_supporter: res.data.data.is_supporter || false}); 
                this.setState({ totale_ordini: res.data.data.totale_ordini || 0}); 
                this.setState({ totale_ordini_formazione: res.data.data.totale_ordini_formazione || 0}); 
                this.setState({ totale_ordini_da_colleghi: res.data.data.ordini_da_colleghi || 0}); 
                this.setState({ in_corso_supporter: res.data.data.in_corso_supporter || []}); 
                this.setState({ in_corso_math: res.data.data.in_corso_math || []}); 
                this.setState({ in_corso_italian: res.data.data.in_corso_italian || []}); 
                this.setState({ docente_primo_ordine_math: res.data.data.docente_primo_ordine_math || false}); 
                this.setState({ docente_primo_ordine_italian: res.data.data.docente_primo_ordine_italian || false}); 
                this.setState({ attivi: res.data.data.attivi || []}); 
                this.setState({ elenco_progetti_attivi: res.data.data.elenco_progetti_attivi || []}); 
                this.setState({ elenco_docenti_referenti_progetto: res.data.data.elenco_docenti_referenti_progetto || []}); 
                this.setState({ elenco_categorie_classe: res.data.data.elenco_categorie_classe || []}); 
                this.setState({ avviso_attivo: res.data.data.avviso_attivo || 0});
                this.setState({ testo_avviso: res.data.data.testo_avviso || ""});
                this.setState({ iscrizioni_supporter_are_active: res.data.data.iscrizioni_supporter_are_active || false });
                this.setState({ iscrizioni_mxt_are_active: res.data.data.iscrizioni_mxt_are_active || false });
                this.setState({ iscrizioni_ixt_are_active: res.data.data.iscrizioni_ixt_are_active || false });
                this.setState({ id_progetto_sup: res.data.data.id_progetto_sup || null });
                this.setState({ id_progetto_mxt: res.data.data.id_progetto_mxt || null });
                this.setState({ id_progetto_ixt: res.data.data.id_progetto_ixt || null });
                this.setState({ edizione_attiva: res.data.data.edizione_attiva || null });
                this.setState({ spedizione_attiva: Boolean(res.data.data.spedizione_attiva) || false });
                this.setState({ n_ordini_edizioni_precedenti: res.data.data.n_ordini_edizioni_precedenti});
                this.setState({ isloading: false });
            }

        }).catch (error => {
            //console.log(error);
            this.setState({ isloading: false});
        });    
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.setState({ authorization: ''});
        Cookies.remove('authorization');
        Cookies.remove('user_email');
        Cookies.remove('nome');
        Cookies.remove('cognome');
        Cookies.remove('wp_user_id');
        Cookies.remove('newuser');
    }

    handleChangeFilterProject(event){
        event.preventDefault();
        this.setState({filtro_progetto: event.target.value});
    }

    handleChangeFilterCategory(event){
        event.preventDefault();
        this.setState({filtro_categoria: event.target.value});
    }

    handleOpenDetailModal(event){
        event.preventDefault();
        let class_to_show = parseInt(event.currentTarget.id.split("_")[1]);
        //console.log(class_to_show);
        this.setState({ class_to_show: class_to_show}, function(){
            this.setState({ opendetailmodal: true });
        });
    }

    handleOpenItalianDetailModal(event){
        event.preventDefault();
        let order_to_show = parseInt(event.currentTarget.id.split("_")[1]);
        //console.log(order_to_show);
        this.setState({ order_to_show: order_to_show}, function(){
            this.setState({ openitaliandetailmodal: true });
        });
    }

    handleOpenMathDetailModal(event){
        event.preventDefault();
        let order_to_show = parseInt(event.currentTarget.id.split("_")[1]);
        //console.log(order_to_show);
        this.setState({ order_to_show: order_to_show}, function(){
            this.setState({ openmathdetailmodal: true });
        });
    }

    handleCloseDetailModal(event){
        event.preventDefault();
        this.setState({ opendetailmodal: false });
    }

    handleCloseItalianDetailModal(event){
        event.preventDefault();
        this.setState({ openitaliandetailmodal: false });
    }

    handleCloseMathDetailModal(event){
        event.preventDefault();
        this.setState({ openmathdetailmodal: false });
    }

    handleUserMenuClick(event){
        //console.log(event.currentTarget);
        this.setState({ anchorElUserMenu: event.currentTarget});
    }

    handleCloseUserMenu(event){
        this.setState({ anchorElUserMenu: null});
    }

    handleModifyAddress(event){
        event.preventDefault();
        
        let project = event.currentTarget.id;

        this.setState({ modifyaddressenabled: true}, function(){
            if (project === "math"){

                this.setState({
                     projectaddress: project,
                     sped_nome: this.state.in_corso_math[0].sped_nome,
                     sped_cognome: this.state.in_corso_math[0].sped_cognome,
                     sped_nome_scuola: this.state.in_corso_math[0].sped_nome_scuola,
                     sped_indirizzo: this.state.in_corso_math[0].sped_indirizzo,
                     sped_citta: this.state.in_corso_math[0].sped_citta,
                     sped_provincia: this.state.in_corso_math[0].sped_provincia,
                     sped_cap: this.state.in_corso_math[0].sped_cap,
                     sped_cellulare: this.state.in_corso_math[0].sped_cellulare,
                     note_consegna: this.state.in_corso_math[0].note_consegna,
                }, function(){
                    let required_field_are_filled = this.requiredFieldsAreFilled();
                    let fields_are_valid = this.fieldsAreValid();
                    
                    if (required_field_are_filled && fields_are_valid) {
                        this.setState({ confirmaddressenabled: true });
                    } else {
                        this.setState({ confirmaddressenabled: false });
                    }
                });
                
            }
    
            if (project === "italian"){
    
                this.setState({
                     projectaddress: project,
                     sped_nome: this.state.in_corso_italian[0].sped_nome,
                     sped_cognome: this.state.in_corso_italian[0].sped_cognome,
                     sped_nome_scuola: this.state.in_corso_italian[0].sped_nome_scuola,
                     sped_indirizzo: this.state.in_corso_italian[0].sped_indirizzo,
                     sped_citta: this.state.in_corso_italian[0].sped_citta,
                     sped_provincia: this.state.in_corso_italian[0].sped_provincia,
                     sped_cap: this.state.in_corso_italian[0].sped_cap,
                     sped_cellulare: this.state.in_corso_italian[0].sped_cellulare,
                     note_consegna: this.state.in_corso_italian[0].note_consegna
                }, function(){
                    let required_field_are_filled = this.requiredFieldsAreFilled();
                    let fields_are_valid = this.fieldsAreValid();
                    
                    if (required_field_are_filled && fields_are_valid) {
                        this.setState({ confirmaddressenabled: true });
                    } else {
                        this.setState({ confirmaddressenabled: false });
                    }
                });    
            }

            
        });

    }

    handleTraining(event){
        event.preventDefault();
        let id_progetto = parseInt(event.currentTarget.id.split("_")[1]);
        let tipo_progetto = parseInt(event.currentTarget.dataset.tipoProgetto);
        let id_ordine_classe;
        let id_ordine;
        if (tipo_progetto == 1 || tipo_progetto == 2){
        //if (id_progetto == 2 || id_progetto == 3){
            id_ordine_classe = event.currentTarget.id.split("_")[2];
            if (id_ordine_classe == "all"){
                id_ordine_classe = null;
            } else {
                id_ordine_classe = parseInt(event.currentTarget.id.split("_")[2]);
            }
        }
        if (tipo_progetto == 4){
        //if (id_progetto == 4){
            id_ordine = parseInt(event.currentTarget.id.split("_")[2]);
        }
        Cookies.set('tipo_progetto', tipo_progetto);
        Cookies.set('id_progetto', id_progetto);
        Cookies.set('id_ordine_classe', id_ordine_classe);
        Cookies.set('id_ordine', id_ordine);
        this.setState({ trainingclicked: true });
    }

    handleRegisterTeam(event){
        event.preventDefault();
        let id_ordine_classe;
        id_ordine_classe = event.currentTarget.id.split("_")[1];
        Cookies.set('id_ordine_classe', id_ordine_classe);
        this.setState({ registerteamclicked: true });
    }

    handleManageTeam(event){
        event.preventDefault();
        let id_ordine_classe;
        id_ordine_classe = event.currentTarget.id.split("_")[1];
        Cookies.set('id_ordine_classe', id_ordine_classe);
        this.setState({ manageteamclicked: true });
    }

    handleConfirmAddress(event){

        event.preventDefault();

        if (this._isMounted) {
            this.setState({ error: false });
            this.setState({ isloading: true });
        }
        var config = require('./config.json');
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        const apiUrl = api_url_backend + 'orders/modifydeliveryaddress';
        var authorization = config.api_auth_backend[env_backend];

        let projectaddress = this.state.projectaddress;
        var ordini = null;

        if (projectaddress === "math"){
            ordini = this.state.in_corso_math;
        }

        if (projectaddress === "italian"){
            ordini = this.state.in_corso_italian;
        }

        var id_ordini_array = [];
        ordini.forEach(function(value, index){
            id_ordini_array.push(value.id);
        });
        var id_ordini = JSON.stringify(id_ordini_array);

        var sped_nome = this.state.sped_nome;
        var sped_cognome = this.state.sped_cognome;
        var sped_nome_scuola = this.state.sped_nome_scuola;
        var sped_indirizzo = this.state.sped_indirizzo;
        var sped_citta = this.state.sped_citta;
        var sped_provincia = this.state.sped_provincia;
        var sped_cap = this.state.sped_cap;
        var sped_cellulare = this.state.sped_cellulare;
        var note_consegna = this.state.note_consegna;

        const settings = {
            headers: { Authorization: `Bearer ${authorization}` }
        };

        axios.patch(apiUrl, { 
            "id_ordini": id_ordini,
            "sped_nome": sped_nome,
            "sped_cognome": sped_cognome,
            "sped_nome_scuola": sped_nome_scuola,
            "sped_indirizzo": sped_indirizzo,
            "sped_citta": sped_citta,
            "sped_provincia": sped_provincia,
            "sped_cap": sped_cap,
            "sped_cellulare": sped_cellulare,
            "note_consegna": note_consegna

        }, settings).then(res => {

            this.setState({ isloading: false }, function(){
                //console.log(res.data.data); 
            });

            if (res.data.success){

                this.reloadOrders();

                this.setState({ sped_nome: ""});
                this.setState({ sped_cognome: ""});
                this.setState({ sped_nome_scuola: ""});
                this.setState({ sped_indirizzo: ""});
                this.setState({ sped_citta: ""});
                this.setState({ sped_provincia: "XX"});
                this.setState({ sped_cap: ""});
                this.setState({ sped_cellulare: ""});
                this.setState({ note_consegna: ""});
                this.setState({ modifyaddressenabled: false});

            } else {
                if (this._isMounted) {

                    this.setState({ error: true});
                    this.setState({ isloading: false });

                }
            }

        }).catch (error => {

            if (this._isMounted) {
                
                //console.log(error);
                this.setState({ isloading: false });
            }

        });

    }

    createData(data, operazione, dettaglio, punti) {
        return { data, operazione, dettaglio, punti };
    }

    renderStatoOrdine(stato){
        switch(stato) {
            case 1:
              return 'INVIATO';
            case 2:
              return 'PAGATO';  
            case 3:
                return 'ATTIVA';    
            default:
              return 'INVIATO';
        }
    }

    renderCategorieOrdine(categorie){
        var result;
        if (typeof categorie !== "undefined" && categorie !== null && categorie !== ""){
            result = categorie.replace("M123", "MEDIE");
            result = result.replace("S12", "SUPERIORI");
        } else {
            result = categorie;
        }
        return result;
    }

    requiredFieldsAreFilled() {

        var required_fields;

        required_fields = [
            'sped_nome',
            'sped_cognome',
            'sped_nome_scuola',
            'sped_indirizzo',
            'sped_citta',
            'sped_cap',
            'sped_cellulare'
        ];

        let filled = 0;
        required_fields.forEach((element) => {
            if (this.state[element] !== null) {
                if ( this.state[element].length > 0) {
                    filled = filled + 1;
                }
            }
        });

        if (filled === required_fields.length){
            return true;
        } else {
            return false;
        }
    }

    fieldsAreValid() {

        if (this.validateSpedNome(this.state.sped_nome) === false) {
            //console.log("nome spedizione invalid");
            return false;
        }
        if (this.validateSpedCognome(this.state.sped_cognome) === false) {
            //console.log("cognome spedizione invalid");
            return false;
        }
        if (this.validateSpedNomeScuola(this.state.sped_nome_scuola) === false) {
            //console.log("nome scuola spedizione invalid");
            return false;
        }
        if (this.validateSpedIndirizzo(this.state.sped_indirizzo) === false) {
            //console.log("indirizzo spedizione invalid");
            return false;
        }
        if (this.validateSpedProvincia(this.state.sped_provincia) === false) {
            //console.log("provincia spedizione invalid");
            return false;
        }
        if (this.validateSpedCitta(this.state.sped_citta) === false) {
            //console.log("citta spedizione invalid");
            return false;
        }
        if (this.validateSpedCap(this.state.sped_cap) === false) {
            //console.log("cap spedizione invalid");
            return false;
        }
        if (this.validateSpedCellulare(this.state.sped_cellulare) === false) {
            //console.log("cellulare spedizione invalid");
            return false;
        }
        return true;
    }

    getListaProvincie() {
        let provincie = {
            XX : '', 
            AG : 'Agrigento',
            AL : 'Alessandria',
            AN : 'Ancona',
            AO : 'Aosta',
            AQ : 'L\'Aquila',
            AR : 'Arezzo',
            AP : 'Ascoli-Piceno',
            AT : 'Asti',
            AV : 'Avellino',
            BA : 'Bari',
            BT : 'Barletta-Andria-Trani',
            BL : 'Belluno',
            BN : 'Benevento',
            BG : 'Bergamo',
            BI : 'Biella',
            BO : 'Bologna',
            BZ : 'Bolzano',
            BS : 'Brescia',
            BR : 'Brindisi',
            CA : 'Cagliari',
            CL : 'Caltanissetta',
            CB : 'Campobasso',
            CI : 'Carbonia Iglesias',
            CE : 'Caserta',
            CT : 'Catania',
            CZ : 'Catanzaro',
            CH : 'Chieti',
            CO : 'Como',
            CS : 'Cosenza',
            CR : 'Cremona',
            KR : 'Crotone',
            CN : 'Cuneo',
            EN : 'Enna',
            FM : 'Fermo',
            FE : 'Ferrara',
            FI : 'Firenze',
            FG : 'Foggia',
            FC : 'Forli-Cesena',
            FR : 'Frosinone',
            GE : 'Genova',
            GO : 'Gorizia',
            GR : 'Grosseto',
            IM : 'Imperia',
            IS : 'Isernia',
            SP : 'La-Spezia',
            LT : 'Latina',
            LE : 'Lecce',
            LC : 'Lecco',
            LI : 'Livorno',
            LO : 'Lodi',
            LU : 'Lucca',
            MC : 'Macerata',
            MN : 'Mantova',
            MS : 'Massa-Carrara',
            MT : 'Matera',
            VS : 'Medio Campidano',
            ME : 'Messina',
            MI : 'Milano',
            MO : 'Modena',
            MB : 'Monza-Brianza',
            NA : 'Napoli',
            NO : 'Novara',
            NU : 'Nuoro',
            OG : 'Ogliastra',
            OT : 'Olbia Tempio',
            OR : 'Oristano',
            PD : 'Padova',
            PA : 'Palermo',
            PR : 'Parma',
            PV : 'Pavia',
            PG : 'Perugia',
            PU : 'Pesaro-Urbino',
            PE : 'Pescara',
            PC : 'Piacenza',
            PI : 'Pisa',
            PT : 'Pistoia',
            PN : 'Pordenone',
            PZ : 'Potenza',
            PO : 'Prato',
            RG : 'Ragusa',
            RA : 'Ravenna',
            RC : 'Reggio-Calabria',
            RE : 'Reggio-Emilia',
            RI : 'Rieti',
            RN : 'Rimini',
            RM : 'Roma',
            RO : 'Rovigo',
            SA : 'Salerno',
            SS : 'Sassari',
            SU : 'Sud Sardegna',
            SV : 'Savona',
            SI : 'Siena',
            SR : 'Siracusa',
            SO : 'Sondrio',
            TA : 'Taranto',
            TE : 'Teramo',
            TR : 'Terni',
            TO : 'Torino',
            TP : 'Trapani',
            TN : 'Trento',
            TV : 'Treviso',
            TS : 'Trieste',
            UD : 'Udine',
            VA : 'Varese',
            VE : 'Venezia',
            VB : 'Verbania',
            VC : 'Vercelli',
            VR : 'Verona',
            VV : 'Vibo-Valentia',
            VI : 'Vicenza',
            VT : 'Viterbo',
        };
        return provincie;
    }

    validateSpedNome(value){
        let nome = value;
        const re = /^[a-zàòùèéì ,.'-]+$/i;
        return re.test(nome) || nome.length === 0;
    }

    validateSpedCognome(value){
        let cognome = value;
        const re = /^[a-zA-Zàòùèéì ,.'-]+$/i;
        return re.test(cognome) || cognome.length === 0;
    }

    validateSpedNomeScuola(value){
        let nomescuola = value;
        const re = /^[a-zA-Zàòùèéì() ,."'-]+$/i;
        return re.test(nomescuola) || nomescuola.length === 0;
    }

    validateSpedIndirizzo(value){
        let spedindirizzo = value;
        const re = /^[0-9a-zA-Z/òàùèéì'\s,.-]+$/;
        return re.test(spedindirizzo) || spedindirizzo.length === 0;
    }

    validateSpedCitta(value){
        let citta = value;
        const re = /^[0-9a-zA-Zàòùèéì'\s,-]+$/;
        return re.test(citta) || citta.length === 0;
    }

    validateSpedProvincia(value){
        let provincia = value;
        return provincia !== "XX" || provincia === 0;
    }

    validateSpedCap(value){
        let cap = value;
        const re = /^[0-9]{5}/;
        return re.test(cap) || cap.length === 0;
    }

    validateSpedCellulare(value){
        let cellulare = value;
        const re = /^[0-9\s]{8,20}$/;
        return re.test(cellulare) || cellulare.length === 0;
    }

    validateNoteConsegna(value){
        let note = value;
        const re = /^[0-9a-zA-Zàòùèéì'\s,-]+$/;
        return re.test(note) || note.length === 0; 
    }

    handleChangeSpedNome(event) {
        this.setState({ sped_nome: event.target.value }, function(){
            if (this.validateSpedNome(event.target.value)) {
                this.setState({ sped_nome_error: false });
            } else {
                this.setState({ sped_nome_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ confirmaddressenabled: true });
            } else {
                this.setState({ confirmaddressenabled: false });
            }
        });
    }

    handleChangeSpedCognome(event) {
        this.setState({ sped_cognome: event.target.value }, function(){
            if (this.validateSpedCognome(event.target.value)) {
                this.setState({ sped_cognome_error: false });
            } else {
                this.setState({ sped_cognome_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ confirmaddressenabled: true });
            } else {
                this.setState({ confirmaddressenabled: false });
            }
        });
    }

    handleChangeSpedNomeScuola(event){
        this.setState({ sped_nome_scuola: event.target.value }, function(){
            if (this.validateSpedNomeScuola(event.target.value)) {
                this.setState({ sped_nome_scuola_error: false });
            } else {
                this.setState({ sped_nome_scuola_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ confirmaddressenabled: true });
            } else {
                this.setState({ confirmaddressenabled: false });
            }
        });
    }

    handleChangeSpedIndirizzo(event){
        this.setState({ sped_indirizzo: event.target.value }, function(){
            if (this.validateSpedIndirizzo(event.target.value)) {
                this.setState({ sped_indirizzo_error: false });
            } else {
                this.setState({ sped_indirizzo_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ confirmaddressenabled: true });
            } else {
                this.setState({ confirmaddressenabled: false });
            }
        });
    }

    handleChangeSpedCitta(event){
        this.setState({ sped_citta: event.target.value }, function(){
            if (this.validateSpedCitta(event.target.value)) {
                this.setState({ sped_citta_error: false });
            } else {
                this.setState({ sped_citta_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            
            if (required_field_are_filled && fields_are_valid ) {
                this.setState({ confirmaddressenabled: true });
            } else {
                this.setState({ confirmaddressenabled: false });
            }
        });
    }

    handleChangeSpedProvincia(event){
        this.setState({ sped_provincia: event.target.value },function(){
            if (this.validateSpedProvincia(event.target.value)) {
                this.setState({ sped_provincia_error: false });
            } else {
                this.setState({ sped_provincia_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ confirmaddressenabled: true });
            } else {
                this.setState({ confirmaddressenabled: false });
            }
        });
    }

    handleChangeSpedCap(event){
        this.setState({ sped_cap: event.target.value }, function(){
            if (this.validateSpedCap(event.target.value)) {
                this.setState({ sped_cap_error: false });
            } else {
                this.setState({ sped_cap_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ confirmaddressenabled: true });
            } else {
                this.setState({ confirmaddressenabled: false });
            }
        });
    }

    handleChangeSpedCellulare(event){
        this.setState({ sped_cellulare: event.target.value }, function(){
            if (this.validateSpedCellulare(event.target.value)) {
                this.setState({ sped_cellulare_error: false });
            } else {
                this.setState({ sped_cellulare_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ confirmaddressenabled: true });
            } else {
                this.setState({ confirmaddressenabled: false });
            }
        });
    }

    handleChangeNoteConsegna(event){
        this.setState({ note_consegna: event.target.value }, function(){
            if (this.validateNoteConsegna(event.target.value)) {
                this.setState({ note_consegna_error: false });
            } else {
                this.setState({ note_consegna_error: true });
            }
            let required_field_are_filled = this.requiredFieldsAreFilled();
            let fields_are_valid = this.fieldsAreValid();
            
            if (required_field_are_filled && fields_are_valid) {
                this.setState({ confirmaddressenabled: true });
            } else {
                this.setState({ confirmaddressenabled: false });
            }
        });
    }

    render() {

        var config = require('./config.json');
        
        const autenticato = this.state.authorization !== '';
        const nome = this.state.nome;
        const cognome = this.state.cognome;
        const anchorElUserMenu = this.state.anchorElUserMenu;
        const open = Boolean(anchorElUserMenu);

        const totale_ordini = this.state.totale_ordini;
        const totale_ordini_formazione = this.state.totale_ordini_formazione;
        const totale_ordini_da_colleghi = this.state.totale_ordini_da_colleghi;
        const in_corso_supporter = this.state.in_corso_supporter;
        const in_corso_math = this.state.in_corso_math;
        const in_corso_italian = this.state.in_corso_italian;
        const attivi = this.state.attivi;
        const elenco_progetti_attivi = this.state.elenco_progetti_attivi;
        const elenco_docenti_referenti_progetto = this.state.elenco_docenti_referenti_progetto;
        const elenco_categorie_classe = this.state.elenco_categorie_classe;
        const docente_primo_ordine_math = this.state.docente_primo_ordine_math;
        const docente_primo_ordine_italian = this.state.docente_primo_ordine_italian;

        const modifyaddressenabled = this.state.modifyaddressenabled;

        const sped_nome = this.state.sped_nome;
        const sped_cognome = this.state.sped_cognome;
        const sped_nome_scuola = this.state.sped_nome_scuola;
        const sped_indirizzo = this.state.sped_indirizzo;
        const sped_citta = this.state.sped_citta;
        const sped_provincia = this.state.sped_provincia;
        const sped_cap = this.state.sped_cap;
        const sped_cellulare = this.state.sped_cellulare;
        const note_consegna = this.state.note_consegna;

        const sped_nome_error = this.state.sped_nome_error;
        const sped_cognome_error = this.state.sped_cognome_error;
        const sped_nome_scuola_error = this.state.sped_nome_scuola_error;
        const sped_indirizzo_error = this.state.sped_indirizzo_error;
        const sped_citta_error = this.state.sped_citta_error;
        const sped_provincia_error = this.state.sped_provincia_error;
        const sped_cap_error = this.state.sped_cap_error;
        const sped_cellulare_error = this.state.sped_cellulare_error;
        const note_consegna_error = this.state.note_consegna_error;

        const isloading = this.state.isloading;
        const error = this.state.error;
        const errormessage = this.state.errormessage;
        const confirmaddressenabled = this.state.confirmaddressenabled;

        const is_supporter = this.state.is_supporter;

        const opendetailmodal = this.state.opendetailmodal;
        const openitaliandetailmodal = this.state.openitaliandetailmodal;
        const openmathdetailmodal = this.state.openmathdetailmodal;
        const class_to_show = this.state.class_to_show;
        const order_to_show = this.state.order_to_show;

        const trainingclicked = this.state.trainingclicked;
        const registerteamclicked = this.state.registerteamclicked;
        const manageteamclicked = this.state.manageteamclicked;

        const prebookurl_mxt = "https://matematicapertutti.it/preiscrizione-squadre-2022/?nome=" + nome + "&cognome=" + cognome + "&email=" + this.state.user_email + "&scuola=" + this.state.codice_meccanografico_scuola;
        const prebookurl_ixt = "https://www.italianopertutti.it//preiscrizione-squadre-2022/?nome=" + nome + "&cognome=" + cognome + "&email=" + this.state.user_email + "&scuola=" + this.state.codice_meccanografico_scuola;

        const pulsante_iscrivi_squadre = this.state.pulsante_iscrivi_squadre;

        const newuser = this.state.newuser;

        const avviso_attivo = this.state.avviso_attivo;
        const testo_avviso = this.state.testo_avviso;
        const iscrizioni_supporter_are_active = this.state.iscrizioni_supporter_are_active;
        const iscrizioni_mxt_are_active = this.state.iscrizioni_mxt_are_active;
        const iscrizioni_ixt_are_active = this.state.iscrizioni_ixt_are_active;
        const iscrizioni_squadre_mxt_are_active = this.state.iscrizioni_squadre_mxt_are_active;
        const iscrizioni_squadre_ixt_are_active = this.state.iscrizioni_squadre_ixt_are_active;
        const id_progetto_sup = this.state.id_progetto_sup;
        const id_progetto_mxt = this.state.id_progetto_mxt;
        const id_progetto_ixt = this.state.id_progetto_ixt;
        const edizione_attiva = this.state.edizione_attiva;
        const spedizione_attiva = Boolean(this.state.spedizione_attiva);
        const n_ordini_edizioni_precedenti = this.state.n_ordini_edizioni_precedenti;
        const filtro_categoria = this.state.filtro_categoria;
        const filtro_progetto = this.state.filtro_progetto;

        const nome_scuola = this.state.nome_scuola;
        const citta_scuola = this.state.citta_scuola;

        return (
            <div>
                {trainingclicked && (
                    <Redirect to="/formation" /> 
                )}
                {registerteamclicked && (
                    <Redirect to="/registerteam" /> 
                )}
                {manageteamclicked && (
                    <Redirect to="/manageteam" /> 
                )}
                { autenticato && (
                    <Helmet htmlAttributes={{lang: 'it-IT'}}>
                        <title>Concorsi ToKalon - Homepage</title>
                        <meta name="description" content="Progetti e Iscrizioni in Corso." data-react-helmet="true"/>
                    </Helmet> 
                )}
                {(autenticato && newuser == "true") && (
                    <Redirect to="/newuser" /> 
                )}
                { (autenticato && modifyaddressenabled === false && isloading === false) && ( 
                    <div className="App">
                        <header className="App-header-home">
                            <AppBar position="static" className="WelcomeBar">
                                <Toolbar>
                                    <table className="WelcomeMenu">
                                    <tbody>
                                            <tr>
                                                <td className="LogoMenuColumn">
                                                    <Logo />
                                                </td>
                                                <td className="HomeMenuColumn">
                                                    <HomeButton />
                                                    |
                                                    { iscrizioni_mxt_are_active && (
                                                        <>
                                                        <Link to={"/signupclass/"+id_progetto_mxt}>
                                                            <Button
                                                                size="large"
                                                                id="mxt-signup-button"
                                                                aria-label="Mxt signup"
                                                                aria-controls="mxt-signup-menu"
                                                                color="inherit"
                                                            >Iscrivi classi a Matematica per Tutti</Button>
                                                        </Link>
                                                        |
                                                        </>
                                                    )}
                                                    { n_ordini_edizioni_precedenti > 0 && (
                                                        <>
                                                            <Link to={"/previouseditions/"}>
                                                                <Button
                                                                    size="large"
                                                                    id="previous-edition-button"
                                                                    aria-label="Previous editions"
                                                                    aria-controls="previous-edition-menu"
                                                                    color="inherit"
                                                                >
                                                                    Edizioni precedenti
                                                                </Button>
                                                            </Link>
                                                            |
                                                        </>
                                                    )}
                                                    <TokalonButton />
                                                </td>
                                                <td className="AccountMenuButton">
                                                    { (nome === "" || cognome === "") ? (
                                                        <>
                                                            {/* <SocialButtonsInHeader /> */}
                                                            <Link to="/profile">
                                                                <Button
                                                                    className="AccountName"
                                                                    size="large"
                                                                    id="user-menu-button"
                                                                    aria-label="account of current user"
                                                                    aria-controls="user-menu"
                                                                    //aria-haspopup="true"
                                                                    //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                    //onClick={this.handleUserMenuClick}
                                                                    color="inherit"
                                                                >
                                                                    {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                    <span className="WelcomeUser">{/* Bentornato */}</span>
                                                                </Button>
                                                            </Link>
                                                            |
                                                            <Button
                                                                className=""
                                                                size="large"
                                                                color="inherit"
                                                                id="logout-button"
                                                                onClick={this.handleLogoutClick}
                                                            >
                                                                Esci
                                                            </Button>
                                                        </>
                                                    ): (
                                                        <>
                                                            {/* <SocialButtonsInHeader /> */}
                                                            <Link to="/profile">
                                                                <Button
                                                                    className=""
                                                                    size="large"
                                                                    id="user-menu-button"
                                                                    aria-label="account of current user"
                                                                    aria-controls="user-menu"
                                                                    //aria-haspopup="true"
                                                                    //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                    //onClick={this.handleUserMenuClick}
                                                                    color="inherit" 
                                                                >
                                                                    {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                    <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome }
                                                                </Button>
                                                            </Link>
                                                            |
                                                            <Button
                                                                className=""
                                                                size="large"
                                                                color="inherit"
                                                                id="logout-button"
                                                                onClick={this.handleLogoutClick}
                                                            >
                                                                Esci
                                                            </Button>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Menu
                                        id="user-menu"
                                        anchorEl={anchorElUserMenu}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={open}
                                        onClose={this.handleCloseUserMenu}
                                        MenuListProps={{
                                            'aria-labelledby': 'user-menu-button',
                                        }}
                                    >
                                        <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                        <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                                    </Menu>
                                </Toolbar>
                            </AppBar>
                            {/* <Subheader></Subheader> */}
                        {/* <div className="separator"></div> */}
                        {/* <div className="Logo-Bar">
                            <div className="Logo-Box"><img src={logo1} alt="logo1" height="60"></img></div>
                            <div className="Logo-Box"><img src={logo2} alt="logo2" height="60"></img></div>
                            <div className="Logo-Box"><img src={logo3} alt="logo3" height="85"></img></div>
                        </div> */}
                        {/* <div className="Page-title-top Orange">Progetti ToKalon</div> */}
                        {/* <div className="Page-subtitle-top">Qui potrai iscriverti, acquistare e gestire tutte le edizioni di Matematica per Tutti, Italiano per tutti e diventare sostenitore di ToKalon.</div> */}
                        
                        {/*Avviso personalizzato per loggati*/}
                        { (avviso_attivo == 1) && (
                        <div className="AdviceBox" dangerouslySetInnerHTML={{ __html: testo_avviso }} />
                        )}

                        {( totale_ordini === 0 && attivi.length === 0) && (
                            <div className="Page-subtitle-top"><strong>Non hai ancora iscritto nessuna classe :(</strong></div>
                        )}
                        {/* <div className="Page-subtitle-top">Per aggiungere una nuova classe premi uno dei pulsanti "Iscrivi" qui sotto.</div> */}
                        {/* <div className="separator"></div> */}
                        { (is_supporter === false && iscrizioni_supporter_are_active) && (
                            <div className="Form-control Register">
                                <Link to={"/supporter/"+id_progetto_sup}>
                                    <Button size="large" className="Button-90" variant="contained" color="primary">Diventa Sostenitore di ToKalon</Button>
                                </Link>
                            </div>
                        )}
                       {/*  { iscrizioni_mxt_are_active && (
                            <div className="Form-control Register">
                                <Link to={"/signupclass/"+id_progetto_mxt}>
                                    <Button size="large" className="Button-90" variant="contained" color="secondary">Iscrivi classi a Matematica per Tutti</Button>
                                </Link>
                                <Link to="Bando_MPT_2022-23_loghi.pdf" target="_blank" download>
                                    <IconButton color="primary" aria-label="download mxt pdf" component="span"><DownloadIcon /></IconButton>
                                </Link>
                            </div>
                        )} */}
                        { iscrizioni_ixt_are_active && (
                            <div className="Form-control Register">
                                <Link to={"/signupclass/"+id_progetto_ixt}>
                                    <Button size="large" className="Button-90" variant="contained" color="success">Iscrivi classi a Italiano per Tutti</Button>
                                </Link>
                                <Link to="Bando_IxT_2022-23.pdf" target="_blank" download>                            
                                    <IconButton color="primary" aria-label="download ixt pdf" component="span"><DownloadIcon /></IconButton>
                                </Link>
                            </div>
                        )}
                        {/* <div className="Form-control Register">
                            <a href={prebookurl_mxt} target="_blank" >
                                <Button size="large" className="Button-90" variant="contained" color="secondary">Pre iscrivi squadre a Matematica per Tutti</Button>
                            </a>
                        </div>
                        <div className="Form-control Register">
                            <a href={prebookurl_ixt} target="_blank">
                                <Button size="large" className="Button-90" variant="contained" color="success">Pre iscrivi squadre a Italiano per Tutti</Button>
                            </a>
                        </div> */}
                        {  elenco_docenti_referenti_progetto.length > 0 && (
                            <Box>
                                <div className="separator"></div>
                                <div className="Page-subtitle-top">DOCENTI REFERENTI { nome_scuola } - { citta_scuola}</div>
                                { elenco_docenti_referenti_progetto.map((docente, docenteindex) => (
                                    <div key={"docente"+docenteindex} className="Page-subtitle-top">{docente.split("###")[0]}: {docente.split("###")[1]}</div>
                                ))}
                            </Box>
                        )}
                        {( (totale_ordini > 0 || totale_ordini_da_colleghi > 0 || totale_ordini_formazione > 0) && attivi.length > 0) && ( 
                            <Box>
                                <div className="separator"></div>
                                <div className="Page-title-top Green">Iscrizioni completate e Classi ATTIVE</div>
                                <div className="Page-subtitle-top">Qui puoi vedere tutte le iscrizioni completate e le classi per cui è stato attivato l'accesso all'area RISORSE.</div>
                                {/* <div className="Page-subtitle-top">Tutti i materiali collegati a queste iscrizioni sono già stati spediti.</div> */}

                                <TableContainer className="TableContainer">
                                    <Table className="TabellaClassi" sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead className="HeadTabellaClassi">   
                                            <TableRow>
                                                {/* <TableCell align="center"></TableCell> */}
                                                <TableCell align="center">n° ISCRIZIONE</TableCell>
                                                <TableCell align="center">PROGETTO</TableCell>
                                                <TableCell align="center">SCUOLA</TableCell>
                                                <TableCell align="center">DOCENTI</TableCell>
                                                <TableCell align="center">CLASSE E SEZIONE</TableCell>
                                                <TableCell align="center">COMPETIZIONE</TableCell>
                                                <TableCell align="center">CATEGORIA</TableCell>
                                                <TableCell align="center">STATO</TableCell>
                                                <TableCell align="center">AZIONI</TableCell>
                                                <TableCell align="center">SQUADRE</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                {/* <TableCell align="right"></TableCell> */}
                                                <TableCell align="right" >Filtri:</TableCell>
                                                <TableCell align="left" colSpan={2}>
                                                    <Select
                                                        value={filtro_progetto}
                                                        onChange={this.handleChangeFilterProject}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Filtra progetto' }}
                                                        >
                                                        <MenuItem value={"allprj"}>
                                                            Tutti i progetti
                                                        </MenuItem>
                                                        {elenco_progetti_attivi.map((progetto, index) => (
                                                            <MenuItem key={index} value={progetto.split("###")[0]}>{progetto.split("###")[1]}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right" colSpan={2}>
                                                <Select
                                                        value={filtro_categoria}
                                                        onChange={this.handleChangeFilterCategory}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Filtra progetto' }}
                                                        >
                                                        <MenuItem value={"allcat"}>
                                                            Tutte le categorie
                                                        </MenuItem>
                                                        {elenco_categorie_classe.map((categoria, index) => (
                                                             <MenuItem key={index} value={categoria}>{categoria}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>    
                                        </TableHead>
                                        <TableBody className="BodyTabellaClassi">
                                            {attivi.map((ordine, index) => (
                                                <>
                                                { ((filtro_progetto === "allprj" || filtro_progetto === "prj-"+ordine.id_progetto) && ( filtro_categoria === "allcat" || ordine.nomi_categorie.includes(filtro_categoria) )) && ( 
                                                    <TableRow key={index} className={ordine.nomi_categorie+" prj-"+ordine.id_progetto}>
                                                        {/* <TableCell align="center" 
                                                            data-title="Dettaglio"
                                                        >
                                                            {(typeof attivi[index].prodotti !== 'undefined' && attivi[class_to_show].prodotti !== null) && (
                                                                <IconButton id={"detail_" + index} color="primary" aria-label="dettaglio classe" onClick={this.handleOpenDetailModal}>
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            )}
                                                        </TableCell> */}
                                                        <TableCell align="center" 
                                                            data-title="N° iscrizione"
                                                        >
                                                            {ordine.num_ordine}<br />{ordine.data_ordine}
                                                        </TableCell>
                                                        <TableCell align="center"
                                                            data-title="Progetto"
                                                        >
                                                            {ordine.nome_progetto}</TableCell>
                                                        <TableCell align="center"
                                                            data-title="Scuola"
                                                        >
                                                            {ordine.nome_scuola}</TableCell>
                                                        <TableCell align="center"
                                                            data-title="Docenti"
                                                        >
                                                            {ordine.nome_utente.split("###").map((utente, index) => (
                                                                <div key={index}>{utente}</div>
                                                            ))}  
                                                        </TableCell>
                                                        <TableCell align="center"
                                                            data-title="Classe e Sezione"
                                                        >
                                                            {(ordine.classe && ordine.sezione) && (
                                                                <>{ordine.classe} sezione {ordine.sezione}</>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center"
                                                            data-title="Competizione"
                                                        >{ordine?.prodotti?.map((o, i) => (
                                                            <>{o.nome_prodotto}<br /></>
                                                        ))}</TableCell>
                                                        <TableCell align="center"
                                                            data-title="Categoria"
                                                        >{this.renderCategorieOrdine(ordine.nomi_categorie)}</TableCell>
                                                        <TableCell align="center"
                                                            data-title="Stato"
                                                        >{this.renderStatoOrdine(ordine.stato_ordine)}</TableCell>
                                                        <TableCell align="center"
                                                            data-title="Azioni"
                                                        >
                                                            {/*((attivi[index].id_progetto == 2 || attivi[index].id_progetto == 3) && (attivi[index].tipo_ordine == 1)) && (*/}
                                                            {((attivi[index].tipo_progetto == 1 || attivi[index].tipo_progetto == 2) && (attivi[index].tipo_ordine == 1)) && (
                                                                <Button size="small" id={"tr_"+attivi[index].id_progetto+"_"+attivi[index].id} data-tipo-progetto={attivi[index].tipo_progetto} className="Training" variant="contained" color="primary" onClick={this.handleTraining}>Risorse</Button>
                                                            )}
                                                            {/*((attivi[index].id_progetto == 2 || attivi[index].id_progetto == 3) && (attivi[index].tipo_ordine == 3)) && (*/}
                                                            {((attivi[index].tipo_progetto == 1 || attivi[index].tipo_progetto == 2) && (attivi[index].tipo_ordine == 3)) && (
                                                                <Button size="small" id={"tr_"+attivi[index].id_progetto+"_all"} data-tipo-progetto={attivi[index].tipo_progetto} className="Training" variant="contained" color="primary" onClick={this.handleTraining}>Risorse</Button>
                                                            )}    
                                                            {/*(attivi[index].id_progetto == 4) && (*/}
                                                            {(attivi[index].tipo_progetto == 4) && (
                                                                <Button size="small" id={"tr_"+attivi[index].id_progetto+"_"+attivi[index].num_ordine} data-tipo-progetto={attivi[index].tipo_progetto} className="Training" variant="contained" color="primary" onClick={this.handleTraining}>Risorse</Button>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center"
                                                            data-title="Squadre"
                                                        >
                                                            {/*((attivi[index].id_progetto == 2 || attivi[index].id_progetto == 3) && (attivi[index].tipo_ordine == 1)) && (*/}
                                                            {((attivi[index].tipo_progetto == 1 || attivi[index].tipo_progetto == 2) && (attivi[index].tipo_ordine == 1)) && (
                                                                <Fragment>
                                                                    { attivi[index].numero_squadre > 0 && (
                                                                        <Fragment>
                                                                            <div>{attivi[index].numero_squadre} squadre</div><br></br>
                                                                            <Button size="small" id={"tm_"+attivi[index].id} className="ManageTeam" variant="contained" color="primary" onClick={this.handleManageTeam}>Gestisci squadre</Button>
                                                                            <br/><br/>
                                                                        </Fragment>
                                                                    )}   
                                                                    { ( /*attivi[index].numero_squadre == 0 &&*/ pulsante_iscrivi_squadre == true /*&& ordine.id_progetto == 3*/) && (
                                                                        <>
                                                                            { ( attivi[index].tipo_progetto == 2 && iscrizioni_squadre_ixt_are_active) && (
                                                                                <Button size="small" id={"tm_"+attivi[index].id} className="RegisterTeam" variant="contained" color="primary" onClick={this.handleRegisterTeam}>Iscrivi squadre</Button>
                                                                            )}
                                                                            { ( attivi[index].tipo_progetto == 1 && iscrizioni_squadre_mxt_are_active) && (
                                                                                <Button size="small" id={"tm_"+attivi[index].id} className="RegisterTeam" variant="contained" color="primary" onClick={this.handleRegisterTeam}>Iscrivi squadre</Button>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </Fragment>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Modal
                                    open={opendetailmodal}
                                    onClose={this.handleCloseDetailModal}
                                    onClick={this.handleCloseDetailModal}
                                    aria-labelledby="modal-detail-title"
                                    aria-describedby="modal-detail-description"
                                >
                                    <Box className="Modal"
                                        style={{width: "auto", minWidth: "400px"}}
                                        onClick={this.sub_handleCloseDetailModal}>
                                         { class_to_show !== "" && (
                                            <TableContainer className="TableContainer">
                                                {(typeof attivi[class_to_show].prodotti !== 'undefined' && attivi[class_to_show].prodotti !== null) && (
                                                <Table className="TabellaClassi" sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead className="HeadTabellaClassi">   
                                                            <TableRow>
                                                                <TableCell align="right">QUANTITA</TableCell>
                                                                <TableCell align="right">PRODOTTO</TableCell>
                                                                <TableCell align="right">IMPORTO</TableCell>
                                                                <TableCell align="right">TOTALE</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody className="BodyTabellaClassi">
                                                            {attivi[class_to_show].prodotti.map((prodotto, prodindex) => (
                                                                <TableRow key={prodindex}>
                                                                    <TableCell align="center" 
                                                                        data-title="Quantità"
                                                                    >
                                                                        {prodotto.quantita}
                                                                    </TableCell>
                                                                    <TableCell align="center" 
                                                                        data-title="Prodotto"
                                                                    >
                                                                        {prodotto.nome_prodotto}
                                                                    </TableCell>
                                                                    <TableCell align="center" 
                                                                        data-title="Importo"
                                                                    >
                                                                        {prodotto.importo_prodotto}€
                                                                    </TableCell>
                                                                    <TableCell align="center" 
                                                                        data-title="Totale"
                                                                    >
                                                                        {(prodotto.quantita * prodotto.importo_prodotto).toFixed(2)}€
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                )}   
                                            </TableContainer>
                                        )}
                                    </Box>
                                </Modal> 
                            </Box>
                        )}
                        {( totale_ordini > 0 && in_corso_supporter.length > 0) && (
                            <Box>
                                <div className="separator"></div>
                                <div className="Page-title-top Orange">Iscrizioni in corso Diventa Sostenitore {edizione_attiva}</div>
                                <div className="Page-subtitle-top">Qui puoi seguire lo stato delle iscrizioni che hai inviato.</div>

                                <TableContainer className="TableContainer">
                                    <Table className="TabellaClassi" sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead className="HeadTabellaClassi">   
                                            <TableRow>
                                                <TableCell>n° ISCRIZIONE</TableCell>
                                                <TableCell align="right">PROGETTO</TableCell>
                                                {/* <TableCell align="right">SCUOLA</TableCell> */}
                                                <TableCell align="right">DOCENTE</TableCell>
                                                {/* <TableCell align="right">CLASSE</TableCell>
                                                <TableCell align="right">SEZIONE</TableCell> */}
                                                <TableCell align="right">STATO</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="BodyTabellaClassi">
                                            {in_corso_supporter.map((ordine, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center"
                                                        data-title="N° Iscrizione"
                                                    >
                                                        {ordine.id}<br />{ordine.data_richiesta}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Progetto"
                                                    >{ordine.nome_progetto}</TableCell>
                                                    {/* <TableCell align="center"></TableCell> */}
                                                    <TableCell align="center"
                                                        data-title="Docente"
                                                    >{ordine.nome_utente}</TableCell>
                                                    {/* <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell> */}
                                                    <TableCell align="center">{this.renderStatoOrdine(ordine.stato_ordine)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}
                        {( totale_ordini > 0 && in_corso_math.length > 0) && (
                            <Box>
                                <div className="separator"></div>
                                <div className="Page-title-top Orange">Iscrizioni in corso Matematica per Tutti Edizione {edizione_attiva}</div>
                                <div className="Page-subtitle-top">Qui puoi seguire lo stato delle iscrizioni che hai inviato.</div>

                                <TableContainer className="TableContainer">
                                    <Table className="TabellaClassi" sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead className="HeadTabellaClassi">   
                                            <TableRow>
                                                {/* <TableCell align="right"></TableCell> */}
                                                <TableCell align="right">n° ISCRIZIONE</TableCell>
                                                <TableCell align="right">SCUOLA</TableCell>
                                                <TableCell align="right">DOCENTI</TableCell>
                                                <TableCell align="right">CLASSI</TableCell>
                                                <TableCell align="right">COSTO</TableCell>
                                                <TableCell align="right">PAGAMENTO</TableCell>
                                                <TableCell align="right">STATO</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="BodyTabellaClassi">
                                            {in_corso_math.map((ordine, index) => (
                                                <TableRow key={index}>
                                                    {/* <TableCell align="center" 
                                                        data-title="Dettaglio"
                                                    >
                                                        {(typeof in_corso_math[index].prodotti !== 'undefined' && in_corso_math[index].prodotti !== null) && (
                                                            <IconButton id={"orderdetail_" + index} color="primary" aria-label="dettaglio iscrizione" onClick={this.handleOpenMathDetailModal}>
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        )}
                                                    </TableCell> */}
                                                    <TableCell align="center"
                                                        data-title="N° Iscrizione"
                                                    >
                                                        {ordine.id}<br />{ordine.data_richiesta}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Scuola"
                                                    >{ordine.nome_scuola}</TableCell>
                                                    <TableCell align="center"
                                                        data-title="Docenti"
                                                    >
                                                        <div>{ordine.nome_utente}</div>
                                                        {ordine.altri_docenti.map((docente, index) => (
                                                            <div key={index}>{docente}</div>
                                                        ))}  
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Classi"
                                                    >
                                                    {ordine.classi.map((classe, index) => (
                                                        <div key={index}>{classe.nome} <span style={{color: "red"}}>{classe.pagato}</span></div>
                                                    ))}  
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Costo"
                                                    >{ordine.importo_totale}€</TableCell>
                                                    <TableCell align="center"
                                                        data-title="Pagamento"
                                                    >{ordine.nome_pagamento}</TableCell>
                                                    <TableCell align="center"
                                                        data-title="Stato"
                                                    >{this.renderStatoOrdine(ordine.stato_ordine)}</TableCell>
                                                </TableRow>
                                            ))}
                                            {(docente_primo_ordine_math && spedizione_attiva) && (
                                             <TableRow key={9999999}>
                                                <TableCell colSpan={5} align="center">
                                                    <strong>Questa/e iscrizione/i saranno spedite tutti a questo indirizzo: {in_corso_math[0].sped_nome_scuola} - {in_corso_math[0].sped_indirizzo} - {in_corso_math[0].sped_cap} - {in_corso_math[0].sped_citta}</strong>
                                                </TableCell>
                                                <TableCell align="right" colSpan={3}>
                                                        <Button id="math" size="small" className="EditAddress" variant="contained" color="primary" onClick={this.handleModifyAddress}>Modifica indirizzo di spedizione</Button>
                                                </TableCell>
                                             </TableRow>
                                              )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Modal
                                    open={openmathdetailmodal}
                                    onClose={this.handleCloseMathDetailModal}
                                    onClick={this.handleCloseMathDetailModal}
                                    aria-labelledby="modal-orderdetail-title"
                                    aria-describedby="modal-orderdetail-description"
                                >
                                    <Box className="Modal"
                                        style={{width: "auto", minWidth: "400px"}}
                                        onClick={this.handleCloseMathDetailModal}>
                                            { (order_to_show !== "" && openmathdetailmodal ) && (
                                                <TableContainer className="TableContainer">
                                                    {(typeof in_corso_math[order_to_show].prodotti !== 'undefined' && in_corso_math[order_to_show].prodotti !== null) && (
                                                        <Table className="TabellaClassi" sx={{ minWidth: 700 }} aria-label="customized table">
                                                            <TableHead className="HeadTabellaClassi">   
                                                                <TableRow>
                                                                    <TableCell align="right">CLASSE</TableCell>
                                                                    <TableCell align="right">QUANTITA</TableCell>
                                                                    <TableCell align="right">PRODOTTO</TableCell>
                                                                    <TableCell align="right">IMPORTO</TableCell>
                                                                    <TableCell align="right">TOTALE</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody className="BodyTabellaClassi">
                                                                {in_corso_math[order_to_show].prodotti.map((prodotto, prodindex) => (
                                                                    <TableRow key={prodindex}>
                                                                        <TableCell align="center" 
                                                                            data-title="Classe"
                                                                        >
                                                                            {prodotto.classe} - Sez. {prodotto.sezione}
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Quantità"
                                                                        >
                                                                            {prodotto.quantita}
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Prodotto"
                                                                        >
                                                                            {prodotto.nome_prodotto}
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Importo"
                                                                        >
                                                                            {prodotto.importo_prodotto}€
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Totale"
                                                                        >
                                                                            {(prodotto.quantita * prodotto.importo_prodotto).toFixed(2)}€
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    )}
                                                </TableContainer>
                                            )}
                                    </Box>
                                </Modal>
                                <Modal
                                    open={openitaliandetailmodal}
                                    onClose={this.handleCloseItalianDetailModal}
                                    onClick={this.handleCloseItalianDetailModal}
                                    aria-labelledby="modal-orderdetail-title"
                                    aria-describedby="modal-orderdetail-description"
                                >
                                    <Box className="Modal"
                                        style={{width: "auto", minWidth: "400px"}}
                                        onClick={this.handleCloseItalianDetailModal}>
                                            { ( order_to_show !== "" && openitaliandetailmodal) && (
                                                <TableContainer className="TableContainer">
                                                    {(typeof in_corso_italian[order_to_show].prodotti !== 'undefined' && in_corso_italian[order_to_show].prodotti !== null) && (
                                                        <Table className="TabellaClassi" sx={{ minWidth: 700 }} aria-label="customized table">
                                                            <TableHead className="HeadTabellaClassi">   
                                                                <TableRow>
                                                                    <TableCell align="right">CLASSE</TableCell>
                                                                    <TableCell align="right">QUANTITA</TableCell>
                                                                    <TableCell align="right">PRODOTTO</TableCell>
                                                                    <TableCell align="right">IMPORTO</TableCell>
                                                                    <TableCell align="right">TOTALE</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody className="BodyTabellaClassi">
                                                                {in_corso_italian[order_to_show].prodotti.map((prodotto, prodindex) => (
                                                                    <TableRow key={prodindex}>
                                                                        <TableCell align="center" 
                                                                            data-title="Classe"
                                                                        >
                                                                            {prodotto.classe} - Sez. {prodotto.sezione}
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Quantità"
                                                                        >
                                                                            {prodotto.quantita}
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Prodotto"
                                                                        >
                                                                            {prodotto.nome_prodotto}
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Importo"
                                                                        >
                                                                            {prodotto.importo_prodotto}€
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Totale"
                                                                        >
                                                                            {(prodotto.quantita * prodotto.importo_prodotto).toFixed(2)}€
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    )}
                                                </TableContainer>
                                            )}
                                    </Box>
                                </Modal>
                            </Box>
                        )}
                        {( totale_ordini > 0 && in_corso_italian.length > 0) && ( 
                            <Box>       
                                <div className="separator"></div>
                                <div className="Page-title-top Orange">Iscrizioni in corso Italiano per Tutti Edizione {edizione_attiva}</div>
                                <div className="Page-subtitle-top">Qui puoi seguire lo stato delle iscrizioni che hai inviato.</div>

                                <TableContainer className="TableContainer">
                                    <Table className="TabellaClassi" sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead className="HeadTabellaClassi">   
                                            <TableRow>
                                                {/* <TableCell align="right"></TableCell> */}
                                                <TableCell align="right">n° ISCRIZIONE</TableCell>
                                                <TableCell align="right">SCUOLA</TableCell>
                                                <TableCell align="right">DOCENTI</TableCell>
                                                <TableCell align="right">CLASSI</TableCell>
                                                <TableCell align="right">COSTO</TableCell>
                                                <TableCell align="right">PAGAMENTO</TableCell>
                                                <TableCell align="right">STATO</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="BodyTabellaClassi">
                                            {in_corso_italian.map((ordine, index) => (
                                                <TableRow key={index}>
                                                    {/* <TableCell align="center" 
                                                        data-title="Dettaglio"
                                                    >
                                                        {(typeof in_corso_italian[index].prodotti !== 'undefined' && in_corso_italian[index].prodotti !== null) && (
                                                            <IconButton id={"orderdetail_" + index} color="primary" aria-label="dettaglio iscrizione" onClick={this.handleOpenItalianDetailModal}>
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        )}
                                                    </TableCell> */}
                                                    <TableCell align="center"
                                                        data-title="N° Iscrizione"
                                                    >
                                                        {ordine.id}<br />{ordine.data_richiesta}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Scuola"
                                                    >{ordine.nome_scuola}</TableCell>
                                                    <TableCell align="center"
                                                        data-title="Docenti"
                                                    >
                                                        <div>{ordine.nome_utente}</div>
                                                        {ordine.altri_docenti.map((docente, index) => (
                                                                <div key={index}>{docente}</div>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Classi"
                                                    >
                                                        {ordine.classi.map((classe, index) => (
                                                            <div key={index}>{classe.nome} <span style={{color: "red"}}>{classe.pagato}</span></div>
                                                        ))}  
                                                    </TableCell>
                                                    <TableCell align="center"
                                                        data-title="Costo"
                                                    >{ordine.importo_totale}€</TableCell>
                                                    <TableCell align="center"
                                                        data-title="Pagamento"
                                                    >{ordine.nome_pagamento}</TableCell>
                                                    <TableCell align="center"
                                                        data-title="Stato"
                                                    >{this.renderStatoOrdine(ordine.stato_ordine)}</TableCell>
                                                </TableRow>
                                            ))}
                                             {(docente_primo_ordine_italian && spedizione_attiva) && (
                                             <TableRow key={9999999}>
                                                <TableCell colSpan={5} align="center">
                                                    <strong>Questa/e iscrizione/i saranno spedite tutti a questo indirizzo: {in_corso_italian[0].sped_nome_scuola} - {in_corso_italian[0].sped_indirizzo} - {in_corso_italian[0].sped_cap} - {in_corso_italian[0].sped_citta}</strong>
                                                </TableCell>
                                                <TableCell align="right" colSpan={3}>
                                                    <Button id="italian" size="small" className="EditAddress" variant="contained" color="primary" onClick={this.handleModifyAddress}>Modifica indirizzo di spedizione</Button>
                                                </TableCell>
                                             </TableRow>
                                             )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Modal
                                    open={openmathdetailmodal}
                                    onClose={this.handleCloseMathDetailModal}
                                    onClick={this.handleCloseMathDetailModal}
                                    aria-labelledby="modal-orderdetail-title"
                                    aria-describedby="modal-orderdetail-description"
                                >
                                    <Box className="Modal"
                                        style={{width: "auto", minWidth: "400px"}}
                                        onClick={this.handleCloseMathDetailModal}>
                                            { (order_to_show !== "" && openmathdetailmodal ) && (
                                                <TableContainer className="TableContainer">
                                                    {(typeof in_corso_math[order_to_show].prodotti !== 'undefined' && in_corso_math[order_to_show].prodotti !== null) && (
                                                        <Table className="TabellaClassi" sx={{ minWidth: 700 }} aria-label="customized table">
                                                            <TableHead className="HeadTabellaClassi">   
                                                                <TableRow>
                                                                    <TableCell align="right">CLASSE</TableCell>
                                                                    <TableCell align="right">QUANTITA</TableCell>
                                                                    <TableCell align="right">PRODOTTO</TableCell>
                                                                    <TableCell align="right">IMPORTO</TableCell>
                                                                    <TableCell align="right">TOTALE</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody className="BodyTabellaClassi">
                                                                {in_corso_math[order_to_show].prodotti.map((prodotto, prodindex) => (
                                                                    <TableRow key={prodindex}>
                                                                        <TableCell align="center" 
                                                                            data-title="Classe"
                                                                        >
                                                                            {prodotto.classe} - Sez. {prodotto.sezione}
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Quantità"
                                                                        >
                                                                            {prodotto.quantita}
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Prodotto"
                                                                        >
                                                                            {prodotto.nome_prodotto}
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Importo"
                                                                        >
                                                                            {prodotto.importo_prodotto}€
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Totale"
                                                                        >
                                                                            {(prodotto.quantita * prodotto.importo_prodotto).toFixed(2)}€
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    )}
                                                </TableContainer>
                                            )}
                                    </Box>
                                </Modal>
                                <Modal
                                    open={openitaliandetailmodal}
                                    onClose={this.handleCloseItalianDetailModal}
                                    onClick={this.handleCloseItalianDetailModal}
                                    aria-labelledby="modal-orderdetail-title"
                                    aria-describedby="modal-orderdetail-description"
                                >
                                    <Box className="Modal"
                                        style={{width: "auto", minWidth: "400px"}}
                                        onClick={this.handleCloseItalianDetailModal}>
                                            { ( order_to_show !== "" && openitaliandetailmodal) && (
                                                <TableContainer className="TableContainer">
                                                    {(typeof in_corso_italian[order_to_show].prodotti !== 'undefined' && in_corso_italian[order_to_show].prodotti !== null) && (
                                                        <Table className="TabellaClassi" sx={{ minWidth: 700 }} aria-label="customized table">
                                                            <TableHead className="HeadTabellaClassi">   
                                                                <TableRow>
                                                                    <TableCell align="right">CLASSE</TableCell>
                                                                    <TableCell align="right">QUANTITA</TableCell>
                                                                    <TableCell align="right">PRODOTTO</TableCell>
                                                                    <TableCell align="right">IMPORTO</TableCell>
                                                                    <TableCell align="right">TOTALE</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody className="BodyTabellaClassi">
                                                                {in_corso_italian[order_to_show].prodotti.map((prodotto, prodindex) => (
                                                                    <TableRow key={prodindex}>
                                                                        <TableCell align="center" 
                                                                            data-title="Classe"
                                                                        >
                                                                            {prodotto.classe} - Sez. {prodotto.sezione}
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Quantità"
                                                                        >
                                                                            {prodotto.quantita}
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Prodotto"
                                                                        >
                                                                            {prodotto.nome_prodotto}
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Importo"
                                                                        >
                                                                            {prodotto.importo_prodotto}€
                                                                        </TableCell>
                                                                        <TableCell align="center" 
                                                                            data-title="Totale"
                                                                        >
                                                                            {(prodotto.quantita * prodotto.importo_prodotto).toFixed(2)}€
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    )}
                                                </TableContainer>
                                            )}
                                    </Box>
                                </Modal>
                            </Box>
                        )}
                        {/* <div className="separator"></div>
                        { n_ordini_edizioni_precedenti > 0 && (
                            <div className="Form-control Register">
                                <Link to={"/previouseditions/"}>
                                    <Button size="large" className="Button-90" variant="contained" color="primary">Edizioni precedenti</Button>
                                </Link>
                            </div>
                        )} */}
                        <div className="separator"></div>
                        <div className="adaptive-home"></div>
                        </header>
                        <AppBar position="static" className="Footer">
                            <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                        </AppBar>
                    </div>
                )}
                { (autenticato && modifyaddressenabled) && ( 
                    <div className="App">
                        <header className="App-header-home">
                            <AppBar position="static" className="WelcomeBar">
                                <Toolbar>
                                    <table className="WelcomeMenu">
                                    <tbody>
                                            <tr>
                                                <td className="LogoMenuColumn">
                                                    <Logo />
                                                </td>
                                                <td className="HomeMenuColumn">
                                                    <HomeButton />
                                                    |
                                                    { iscrizioni_mxt_are_active && (
                                                        <>
                                                        <Link to={"/signupclass/"+id_progetto_mxt}>
                                                            <Button
                                                                size="large"
                                                                id="mxt-signup-button"
                                                                aria-label="Mxt signup"
                                                                aria-controls="mxt-signup-menu"
                                                                color="inherit"
                                                            >Iscrivi classi a Matematica per Tutti</Button>
                                                        </Link>
                                                        |
                                                        </>
                                                    )}
                                                    { n_ordini_edizioni_precedenti > 0 && (
                                                        <>
                                                            <Link to={"/previouseditions/"}>
                                                                <Button
                                                                    size="large"
                                                                    id="previous-edition-button"
                                                                    aria-label="Previous editions"
                                                                    aria-controls="previous-edition-menu"
                                                                    color="inherit"
                                                                >
                                                                    Edizioni precedenti
                                                                </Button>
                                                            </Link>
                                                            |
                                                        </>
                                                    )}
                                                    <TokalonButton />
                                                </td>
                                                <td className="AccountMenuButton">
                                                    { (nome === "" || cognome === "") ? (
                                                        <>
                                                            {/* <SocialButtonsInHeader /> */}
                                                            <Link to="/profile">
                                                                <Button
                                                                    className="AccountName"
                                                                    size="large"
                                                                    id="user-menu-button"
                                                                    aria-label="account of current user"
                                                                    aria-controls="user-menu"
                                                                    //aria-haspopup="true"
                                                                    //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                    //onClick={this.handleUserMenuClick}
                                                                    color="inherit"
                                                                >
                                                                    {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                    <span className="WelcomeUser">{/* Bentornato */}</span> 
                                                                </Button>
                                                            </Link>
                                                            |
                                                            <Button
                                                                className=""
                                                                size="large"
                                                                color="inherit"
                                                                id="logout-button"
                                                                onClick={this.handleLogoutClick}
                                                            >
                                                                Esci
                                                            </Button>
                                                        </>
                                                    ): (
                                                    <>
                                                        {/* <SocialButtonsInHeader /> */}
                                                        <Link to="/profile">
                                                            <Button
                                                                className="AccountName"
                                                                size="large"
                                                                id="user-menu-button"
                                                                aria-label="account of current user"
                                                                aria-controls="user-menu"
                                                                //aria-haspopup="true"
                                                                //aria-expanded={Boolean(anchorElUserMenu) ? 'true' : undefined}
                                                                //onClick={this.handleUserMenuClick}
                                                                color="inherit" 
                                                            >
                                                                {/* <FontAwesomeIcon icon={faUser} /> */}
                                                                <span className="WelcomeUser">{/* Bentornato */}</span> { nome + " " + cognome } 
                                                            </Button>
                                                        </Link>
                                                        |
                                                        <Button
                                                            className=""
                                                            size="large"
                                                            color="inherit"
                                                            id="logout-button"
                                                            onClick={this.handleLogoutClick}
                                                        >
                                                            Esci
                                                        </Button>
                                                    </>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Menu
                                        id="user-menu"
                                        anchorEl={anchorElUserMenu}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={open}
                                        onClose={this.handleCloseUserMenu}
                                        MenuListProps={{
                                            'aria-labelledby': 'user-menu-button',
                                        }}
                                    >
                                        <MenuItem><Link to="/profile">Profilo</Link></MenuItem>
                                        <MenuItem onClick={this.handleLogoutClick}>Esci</MenuItem>
                                    </Menu>
                                </Toolbar>
                                
                            </AppBar>
                            {/* <Subheader></Subheader> */}
                            <div className="separator"></div>
                            <div className="Page-title-top Orange">Modifica le informazioni sulla <strong>Spedizione</strong></div>
                            <div className="Page-subtitle-top"><strong>ATTENZIONE: Verifica la correttezza dell'indirizzo di spedizione</strong></div>
                            <Grid container className="Form-control Register Half">
                                <Grid item xs={6}>
                                    { sped_nome_error ? (
                                        <TextField style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255}} id="sped_nome" required placeholder="Nome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedNome} value={ sped_nome }/>
                                    ) : (
                                        <TextField style={{ width : "95%", paddingRight : "19px" }} inputProps={{maxLength : 255}} id="sped_nome" required placeholder="Nome" variant="outlined" onChange={this.handleChangeSpedNome} value={ sped_nome }/>
                                    )}
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "right"}}>
                                    { sped_cognome_error ? (
                                        <TextField style={{ width : "95%"}} id="sped_cognome" inputProps={{maxLength : 255}} required placeholder="Cognome" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedCognome} value={ sped_cognome }/>
                                    ) : (
                                        <TextField style={{ width : "95%"}} id="sped_cognome" inputProps={{maxLength : 255}} required placeholder="Cognome" variant="outlined" onChange={this.handleChangeSpedCognome} value={ sped_cognome }/>
                                    )}
                                </Grid>
                            </Grid> 
                            <div className="Form-control Register">
                                {sped_nome_scuola_error ? (
                                    <TextField id="sped_nome_scuola" inputProps={{maxLength : 255}} required placeholder="Denominazione scuola" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedNomeScuola} value={ sped_nome_scuola }/>
                                ) : (
                                    <TextField id="sped_nome_scuola" inputProps={{maxLength : 255}} required placeholder="Denominazione scuola" variant="outlined" onChange={this.handleChangeSpedNomeScuola} value={ sped_nome_scuola }/>
                                )}
                            </div>
                            <div className="Form-control Register">
                                { sped_indirizzo_error ? (
                                    <TextField id="sped_indirizzo" inputProps={{maxLength : 255}} required placeholder="Indirizzo" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedIndirizzo} value={ sped_indirizzo }/>
                                ) : (
                                    <TextField id="sped_indirizzo" inputProps={{maxLength : 255}} required placeholder="Indirizzo" variant="outlined" onChange={this.handleChangeSpedIndirizzo} value={ sped_indirizzo }/>
                                )}
                            </div>
                            <div className="Form-control Register">
                                { sped_citta_error ? (
                                    <TextField id="sped_citta" inputProps={{maxLength : 255}} required placeholder="Città" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedCitta} value={ sped_citta }/>
                                ) : (
                                    <TextField id="sped_citta" inputProps={{maxLength : 255}} required placeholder="Città" variant="outlined" onChange={this.handleChangeSpedCitta} value={ sped_citta }/>
                                )}
                            </div>
                            <div className="Form-control Register">
                                { sped_provincia_error ? ( 
                                    <FormControl variant="outlined" className="Form-control" error>   
                                        <Select
                                            native
                                            value={sped_provincia}
                                            onChange={this.handleChangeSpedProvincia}
                                        >
                                            { Object.keys(this.getListaProvincie()).map((keyName, keyIndex) => (
                                                <option key={keyName} value={keyName}>{this.getListaProvincie()[keyName]}</option>
                                            ))}
                                        </Select>
                                        <FormHelperText>Valore non valido</FormHelperText>
                                    </FormControl>
                                ) : (
                                    <FormControl variant="outlined" className="Form-control">   
                                        <Select
                                            native
                                            value={sped_provincia}
                                            onChange={this.handleChangeSpedProvincia}
                                        >
                                            { Object.keys(this.getListaProvincie()).map((keyName, keyIndex) => (
                                                <option key={keyName} value={keyName}>{this.getListaProvincie()[keyName]}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                            <div className="Form-control Register">
                                { sped_cap_error ? (
                                    <TextField id="sped_cap" inputProps={{maxLength : 5}} required placeholder="Cap" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedCap} value={ sped_cap }/>
                                ) : (
                                    <TextField id="sped_cap" inputProps={{maxLength : 5}} required placeholder="Cap" variant="outlined" onChange={this.handleChangeSpedCap} value={ sped_cap }/>
                                )}
                            </div>
                            <div className="Form-control Register">
                                { sped_cellulare_error ? (
                                    <TextField id="sped_cellulare" inputProps={{maxLength : 20}} required placeholder="Cellulare" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeSpedCellulare} value={ sped_cellulare }/>
                                ): (
                                    <TextField id="sped_cellulare" inputProps={{maxLength : 20}} required placeholder="Cellulare" variant="outlined" onChange={this.handleChangeSpedCellulare} value={ sped_cellulare }/>
                                )}
                            </div>
                            <div className="Form-control Register">
                                { note_consegna_error ? (
                                    <TextField id="note_consegna" inputProps={{maxLength : 255}} required placeholder="Note per la consegna" error helperText="Valore non valido" variant="outlined" onChange={this.handleChangeNoteConsegna} value={ note_consegna }/>
                                ) : (
                                    <TextField id="note_consegna" inputProps={{maxLength : 255}} required placeholder="Note per la consegna" variant="outlined" onChange={this.handleChangeNoteConsegna} value={ note_consegna }/>
                                )}
                            </div>
                            { isloading && (
                                <CircularProgress className="Progress"/>
                            )}
                            { error && (
                                <div className="Error-message">{ errormessage }</div>
                            )}
                            <div className="Form-control Register">
                                { confirmaddressenabled ? (
                                    <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleConfirmAddress}>Conferma la modifica</Button>
                                ) : (
                                    <Button size="large" type="submit" disabled variant="contained" color="primary" onClick={this.handleConfirmAddress}>Conferma la modifica</Button>
                                )}    
                            </div>
                            <div className="separator"></div>
                        </header>
                        <AppBar position="static" className="Footer">
                            <div>© Copyright {new Date().getFullYear()} – Associazione ToKalon - <Link to="/privacy">Privacy Policy</Link> - Realizzato da <a href="https://www.creab.it/" target="_blank" rel="noreferrer">Creabit.</a></div>
                        </AppBar>
                    </div>
                )}
                { (autenticato === false) && ( 
                    <Redirect to="/login" />
                )}
            </div>
        );
    }
}

export default Home;