import React, { Component } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import {
    Link
} from "react-router-dom";
import logo1 from './logo-mxt.png';
//import logo2 from './logo-cmtk.png';
import logo2 from './logo-associazione-tokalon.png';
import logo3 from './logo-ixt.png';
import {Helmet} from "react-helmet";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import HomeButton from './HomeButton';
import SocialButtonsInHeader from './SocialButtonsInHeader';
import Subheader from './Subheader';
import Logo from './Logo';
import TokalonButton from './TokalonButton';

class Login extends Component {

    _isMounted = false;
    
    constructor(props) {
        super(props);
        this.state = {
            email : "",
            password : "",
            authorization: Cookies.get('authorization') || '',
            wp_user_id: Cookies.get('wp_user_id') || 0,
            error: false,
            errorcode: '',
            errormessage: '',
            isloading: false,
            loginenabled: false,
            emailerror: false,
            newuser: Cookies.get('newuser') || "false",
        };
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            var config = require('./config.json');
            var env_wp = config.env_wp;
            this.setState({reset_password_url_wp: config.reset_password_url_wp[env_wp]}); 
        }       
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    validateEmail() {
        let email = this.state.email;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    handleChangeEmail(event) {
        this.setState({ email: event.target.value }, function(){
            if (this.validateEmail()) {
                this.setState({ emailerror: false });
            } else {
                this.setState({ emailerror: true });
            }
            if (event.target.value.length > 0 && this.state.password.length > 0 && this.validateEmail()) {
                this.setState({ loginenabled: true });
            } else {
                this.setState({ loginenabled: false });
            }
        });
    }

    handleChangePassword(event) {
        this.setState({ password: event.target.value }, function(){
            /* if (this.validatePassword()) {
                this.setState({ passworderror: false });
            } else {
                this.setState({ passworderror: true });
            } */
            if (event.target.value.length > 0 && this.state.email.length > 0 && this.validateEmail()) {
                this.setState({ loginenabled: true });
            } else {
                this.setState({ loginenabled: false });
            }
        });
    }
    
    handleLoginClick(event) {
        event.preventDefault();
        this.setState({ error: false });
        this.setState({ isloading: true });

        var config = require('./config.json');
        var env_wp = config.env_wp;
        var api_url_wp = config.api_url_wp[env_wp];
        var api_auth_wp = config.api_auth_wp[env_wp];
        var env_backend = config.env_backend;
        var api_url_backend = config.api_url_backend[env_backend];
        var api_auth_backend = config.api_auth_backend[env_backend];
        
        const apiUrl = api_url_wp + 'login';
        const apiUrl2 = api_url_backend + 'users/isnew';
        const apiUrl3 = api_url_backend + 'users/isverified';
        const apiUrl4 = api_url_backend + 'users/registerfromwp';
        let email = this.state.email;
        let password = this.state.password;
                
        let authorization = api_auth_wp;

        const settings = {
            headers: { Authorization: `Bearer ${api_auth_backend}` }
        };
        
        //console.log(authorization);

        axios.post(apiUrl, {
             'username': email,
             'password': password
            })
            .then(res => {
                //console.log(res);
                this.setState({ isloading: false });
                //console.log(res.data.data.ID);
                if (res.data.success) {
                    //Verificare se l'utente è verificato
                    axios.post(apiUrl3, { 'email': email }, settings)
                        .then(res3 => {
                            //Verifica se l'utente è nuovo
                            axios.post(apiUrl2, { 'email': email }, settings)
                                .then(res2 => {
                                    Cookies.set('nome', res.data.data.first_name);
                                    Cookies.set('cognome', res.data.data.last_name);
                                    Cookies.set('user_email', email);
                                    Cookies.set('wp_user_id', res.data.data.user.ID);
                                    Cookies.set('authorization', authorization);
                                    Cookies.set('newuser', "true");
                                    this.setState({ email: ''});
                                    this.setState({ password: ''});
                                    if (this._isMounted) {
                                        this.setState({ nome: res.data.data.first_name });
                                        this.setState({ cognome: res.data.data.last_name });
                                        this.setState({ wp_user_id: res.data.data.user.ID});
                                        this.setState({ authorization: authorization });
                                    }
                                })
                                .catch (error2 => {
                                    //console.log(error2?.response?.data?.data?.first_name);
                                    //console.log(error2?.response?.data?.data?.last_name);
                                    //Leggere nome e cognome dall'utente Laravel
                                    Cookies.set('nome', error2?.response?.data?.data?.first_name);
                                    Cookies.set('cognome', error2?.response?.data?.data?.last_name);
                                    Cookies.set('user_email', email);
                                    Cookies.set('wp_user_id', res.data.data.user.ID);
                                    Cookies.set('authorization', authorization);
                                    this.setState({ email: ''});
                                    this.setState({ password: ''});
                                    if (this._isMounted) {
                                        this.setState({ nome: error2?.response?.data?.data?.first_name });
                                        this.setState({ cognome: error2?.response?.data?.data?.last_name });
                                        this.setState({ wp_user_id: res.data.data.user.ID});
                                        this.setState({ authorization: authorization });
                                    }
                                });                            
                        })
                        .catch (error3 => {
                            console.log(error3.response);
                            if (error3?.response?.data?.data?.code === "email_not_found"){
                                //Utente solo WP - Creare utente nell'App e reindirizzare alla pagina per completare i dati mancanti
                                axios.post(apiUrl4, {
                                        'email': email,
                                        'user_id_wp': res.data.data.user.ID,
                                        'first_name': res.data.data.first_name,
                                        'last_name': res.data.data.last_name
                                    }, settings)
                                    .then(res4 => {
                                        Cookies.set('nome', res.data.data.first_name);
                                        Cookies.set('cognome', res.data.data.last_name);
                                        Cookies.set('user_email', email);
                                        Cookies.set('wp_user_id', res.data.data.user.ID);
                                        Cookies.set('authorization', authorization);
                                        Cookies.set('newuser', "true");
                                        this.setState({ email: ''});
                                        this.setState({ password: ''});
                                        if (this._isMounted) {
                                            this.setState({ nome: res.data.data.first_name });
                                            this.setState({ cognome: res.data.data.last_name });
                                            this.setState({ wp_user_id: res.data.data.user.ID});
                                            this.setState({ authorization: authorization });
                                        }
                                    })
                                    .catch (error4 => {
                                        if (this._isMounted) {
                                            this.setState({ error: true});
                                            this.setState({ errorcode: error4.response?.data?.data?.code });
                                            this.setState({ errormessage: error4.response?.data?.data?.message });
                                        }
                                    });
                            } else {
                                if (this._isMounted) {
                                    this.setState({ error: true});
                                    this.setState({ errorcode: error3.response?.data?.data?.code });
                                    this.setState({ errormessage: error3.response?.data?.data?.message });
                                }
                            }
                        });
                    
                    
                } else {
                    //console.log(res.data.data.code);
                    //console.log(res.data.data.message);
                    if (this._isMounted) {
                        this.setState({ error: true});
                        //this.setState({ email: ''});
                        //this.setState({ password: ''});
                        this.setState({ errorcode: res.data.data.code });
                        this.setState({ errormessage: res.data.data.message });
                    }
                }
            }) 
            .catch (error => {
                this.setState({ isloading: false });
                //console.log(error.response);
                //console.log(error.response);
                if (this._isMounted) {
                    this.setState({ error: true});
                    //this.setState({ email: ''});
                    //this.setState({ password: ''});
                    this.setState({ errorcode: error.response?.data?.data?.code });
                    this.setState({ errormessage: error.response?.data?.data?.message });
                }
            })
    }

    render() {
        const autenticato = this.state.authorization !== '';
        const error = this.state.error;
        const errormessage = this.state.errormessage;
        const isloading = this.state.isloading;
        const email = this.state.email;
        const password = this.state.password;
        const loginenabled = this.state.loginenabled;
        const emailerror = this.state.emailerror;
        const newuser = this.state.newuser;
        const reset_password_url_wp = this.state.reset_password_url_wp;

        return (
            <div>
               <Helmet htmlAttributes={{lang: 'it-IT'}}>
                    <title>Concorsi ToKalon - Login</title>
                    <meta name="description" content="Accedi e partecipa a tutti i nostri progetti e alle nostre Iniziative." data-react-helmet="true"/>
               </Helmet> 
               { (newuser == "true" && autenticato) && (
                    <Redirect to="/newuser" /> 
               )}
               { (newuser == "false" && autenticato) && ( 
                   <Redirect to="/" /> 
               )}
               { autenticato === false && (
                    <div className="App">
                        <header className="App-header">
                            <AppBar position="static" className="WelcomeBar">
                                <Toolbar>
                                    <table className="WelcomeMenu">
                                    <tbody>
                                            <tr>
                                                <td className="LogoMenuColumn">
                                                    <Logo />
                                                </td>
                                                <td className="HomeMenuColumn">
                                                    <HomeButton />
                                                    |
                                                    <TokalonButton />
                                                </td>
                                                <td className="AccountMenuButton">
                                                    <>
                                                        {/* <SocialButtonsInHeader /> */}
                                                    </>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Toolbar>
                            </AppBar>
                            {/* <Subheader></Subheader> */}
                            {/* <div className="Logo-Bar">
                                <div className="Logo-Box"><img src={logo1} alt="logo1" height="60"></img></div>
                                <div className="Logo-Box"><img src={logo2} alt="logo2" height="60"></img></div>
                                <div className="Logo-Box"><img src={logo3} alt="logo3" height="85"></img></div>
                            </div> */}
                            <div className="Home-description-blu">
                                <p><strong>Benvenuto nell'area dedicata ai Con-corsi organizzati dall'associazione ToKalon</strong></p>
                                <p>Qui potrai iscriverti, acquistare e gestire tutte le edizioni dei Con-corsi nazionali Matematica per Tutti e Italiano per tutti.</p>
                                <p>Se sei già registrato su <a href="https://www.associazionetokalon.com/">www.associazionetokalon.com</a> puoi fare login usando le stesse credenziali.</p>
                                {/* <p><span className="Home-description-blu">Registrandoti potrai scoprire inoltre come diventare un Sostenitore, per avere fantastici sconto e conoscere in anteprima tutte le novità delle iniziative di ToKalon.</span></p>
                                <p><span className="Home-description-blu">Contribuirai a realizzare insieme a noi... la Scuola che vogliamo!</span></p> */}
                            </div>
                            <div className="Page-title">Login</div>
                            { isloading && (
                                <CircularProgress className="Progress"/>
                            )}
                            { error && (
                                <div className="Error-message">{ errormessage }</div>
                            )}
                            <div className="Form-label">
                                <label>E-mail</label>
                            </div>
                            <div className="Form-control">
                                { emailerror ? (
                                    <TextField id="email" type="email" error helperText="Valore non valido" required variant="outlined" onChange={this.handleChangeEmail} defaultValue={ email }/>
                                ) : (
                                    <TextField id="email" type="email" required variant="outlined" onChange={this.handleChangeEmail} defaultValue={ email }/>
                                )}
                            </div>
                            <div className="Form-label">
                                <label>Password</label>
                            </div>
                            <div className="Form-control">
                                <TextField id="password" type="password" required variant="outlined" onChange={this.handleChangePassword} defaultValue={ password }/>
                            </div>
                            <div className="Form-link PasswordReset">
                                {/* <Link to="/passwordresetlink"> 
                                        Recupera password
                                    </Link>
                                */}
                                <a href={reset_password_url_wp}>
                                    Recupera password
                                </a>
                            </div>
                            <div className="Form-control">
                                { loginenabled ? (
                                    <Button size="large" type="submit" variant="contained" color="primary" onClick={this.handleLoginClick}>LOGIN</Button>
                                ) : (
                                    <Button size="large" type="submit" disabled variant="contained" color="primary" onClick={this.handleLoginClick}>LOGIN</Button>
                                )}    
                            </div>
                            <div className="Form-link">
                                <label>Non sei registrato?</label>
                            </div>
                            <div className="Form-control" style={{marginBottom: "50px"}}>
                                <Link to="/register">
                                    <Button size="large" className="submit" variant="contained" color="secondary">REGISTRATI</Button>
                                </Link>
                            </div>
                            
                        </header>
                    </div>
                )}
         </div>
        );
    }
}

export default Login;